import React from 'react';

import { AudioMessages } from '../../@types/messages';

interface ChatInitButtonProps {
setMessages: React.Dispatch<React.SetStateAction<AudioMessages[]>>;
chatLog: React.ReactNode;
setChatLog: React.Dispatch<React.SetStateAction<React.ReactNode>>;
setVoice: React.Dispatch<React.SetStateAction<string>>;
setAudioSrc: React.Dispatch<React.SetStateAction<string>>;
setIsVoiceSelected: React.Dispatch<React.SetStateAction<boolean>>;
isSending: boolean;
}

const ChatInitButton: React.FC<ChatInitButtonProps> = ({
setMessages,
chatLog,
setChatLog,
setVoice,
setAudioSrc,
setIsVoiceSelected,
isSending
}) => {
const initButtonHandler = () => {
if (chatLog) {
setMessages([]);
setChatLog('');
setVoice('nova');
setAudioSrc('');
setIsVoiceSelected(false);
alert('지금까지의 모든 채팅 기록을 삭제했습니다. 이제 다시 새로운 주제로 채팅을 시작하실 수 있습니다.');
} else {
alert('아직 채팅 기록이 없습니다.');
}
};

return (
<>
<button onClick={initButtonHandler} disabled={isSending}>
초기화
</button>
<br/><br/>
</>
);
};

export default ChatInitButton;
