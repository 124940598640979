import React, { useState } from 'react';

import { searchHandler } from '../common/keydownhandler';

import { ChatData } from '../../@types/data';
import { Messages } from '../../@types/messages';
import result from '../../assets/sound/result.wav';
import OnePagePrior from '../../assets/sound/OnePagePrior.wav';
import OnePageNext from '../../assets/sound/OnePageNext.wav';

interface ChatDataDisplayProps {
roomType: string;
totalCount: number;
currentPage: number;
setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
totalPage: number;
setTotalPage: React.Dispatch<React.SetStateAction<number>>;
userQuery: string;
setUserQuery: React.Dispatch<React.SetStateAction<string>>;
setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
chatData: ChatData[];
setDeleteTarget: React.Dispatch<React.SetStateAction<number | null>>;
isSending: boolean;
setChatDataLoad: React.Dispatch<React.SetStateAction<boolean>>;
messages: Messages[];
setMessages: React.Dispatch<React.SetStateAction<Messages[]>>;
}

const ChatDataDisplay: React.FC<ChatDataDisplayProps> = ({
roomType,
totalCount,
currentPage,
setCurrentPage,
totalPage,
setTotalPage,
userQuery,
setUserQuery,
setSearchQuery,
chatData,
setDeleteTarget,
isSending,
setChatDataLoad,
messages,
setMessages
}) => {
const [visibleAnswerId, setVisibleAnswerId] = useState<number | null>(null);
const [requestedIds, setRequestedIds] = useState<number[]>([]);

const searchButtonHandler = () => {
setCurrentPage(1);
setSearchQuery(userQuery);
const audio = new Audio(result);
audio.play();
};

const previousPageButtonHandler = () => {
setCurrentPage(currentPage - 1)
const audio = new Audio(OnePagePrior);
audio.play();
};

const nextPageButtonHandler = () => {
setCurrentPage(currentPage + 1)
const audio = new Audio(OnePageNext);
audio.play();
};

const registDataHandler = (request: string, answer: string, id: number) => {
setMessages([...messages,
{ role: 'user', content: request },
{ role: 'assistant', content: answer },
]);
setRequestedIds([...requestedIds, id]);
};

const deleteDataHandler = (id: number) => {
setDeleteTarget(id);
};

const initDataHandler = () => {
setMessages([]);
setRequestedIds([]);
alert('요청에서 삭제했습니다.');
};

const toggleAnswerVisibility = (id: number) => {
if (visibleAnswerId === id) {
setVisibleAnswerId(null);
} else {
setVisibleAnswerId(id);
}
};

return (
<>
{chatData.length > 0 && (
<>
{setTotalPage(Math.ceil(totalCount / 10))}
</>
)}

{chatData.length === 0 && (
<>
{setTotalPage(1)}
</>
)}

<p>검색어를 입력하세요.</p>
<p>검색어</p>
<input
type="search"
placeholder="검색어"
value={userQuery}
onChange={e => setUserQuery(e.target.value)}
onKeyDown={searchHandler}
required/>
<br/><br/>
<button id="search-button" onClick={searchButtonHandler}>검색</button>

<h1>기존 채팅 기록</h1>
<br />
<p>총 {totalCount} 건</p>
<br />

{chatData.map((item) => (
<React.Fragment key={item.id}>
<h2 onClick={() => toggleAnswerVisibility(item.id)} style={{ cursor: 'pointer' }}>
{item.requestLines.map((line, index) => (
<React.Fragment key={index}>
{line}
<br />
</React.Fragment>
))}
</h2>
<br />
{visibleAnswerId === item.id &&
item.answerLines.map((line, index) => {
return (
<React.Fragment key={`answerLine-${index}`}>
{roomType === 'businessmail' ? (
<span dangerouslySetInnerHTML={{ __html: line.replace(/(['"])/g, '$1') }} />
) : (
<span>{line.replace(/(['"])/g, '$1')}</span>
)}
<br />
</React.Fragment>
);
})}
<br /><br />
<button
onClick={() => registDataHandler(item.request, item.answer, item.id)}
disabled={requestedIds.includes(item.id) || isSending || messages[messages.length - 1]?.role === 'user'}>
요청에 삽입
</button>
<br /><br />
<button onClick={() => deleteDataHandler(item.id)}>삭제</button>
<br /><br /><br /><br />
</React.Fragment>
))}
{chatData.length > 0 && (
<>
<button
onClick={() => initDataHandler()}
disabled={requestedIds.length === 0 || isSending || messages[messages.length - 1]?.role === 'user'}>
요청 초기화
</button>
<br/><br/>
</>
)}

<p>{currentPage}/{totalPage} 페이지</p>
<br/>
<button
onClick={() => previousPageButtonHandler()}
disabled={currentPage === 1}>
이전
</button>
<br/><br/>
<button
onClick={() => nextPageButtonHandler()}
disabled={currentPage === totalPage}>
다음
</button>
<br/><br/>

{setChatDataLoad(false)}
</>
);
};

export default ChatDataDisplay;
