import React, { useState } from 'react';

import Header from '../../hooks/common/header';
import { OpenaiKey } from '../../hooks/common/key';
import ChatDataLoader from '../../hooks/o1/chatdataloader';
import ChatDataDisplay from '../../hooks/o1/chatdatadisplay';
import ChatDataDeleter from '../../hooks/o1/chatdatadeleter';
import ChatDatasDeleteButton from '../../hooks/o1/chatdatasdeletebutton';
import ChatDatasDeleter from '../../hooks/o1/chatdatasdeleter';
import ChatSendButton from '../../hooks/o1/chatsendbutton';
import ChatInitButton from '../../hooks/o1/chatinitbutton';
import ChatDisplay from '../../hooks/o1/chatdisplay';

import { ChatData } from '../../@types/data';
import { Messages } from '../../@types/messages';

interface GptProps {
title: string;
roomType: string;
}

const Gpt: React.FC<GptProps> = ({
title,
roomType
}) => {
const [totalCount, setTotalCount] = useState<number>(0);
const [currentPage, setCurrentPage] = useState<number>(1);
const [totalPage, setTotalPage] = useState<number>(1);
const [userQuery, setUserQuery] = useState<string>('');
const [searchQuery, setSearchQuery] = useState<string>('');
const [chatData, setChatData] = useState<ChatData[]>([]);
const [model, setModel] = useState<string>('o1-mini');
const [userInput, setUserInput] = useState<string>('');
const [messages, setMessages] = useState<Messages[]>([]);
const [saveResult, setSaveResult] = useState<string>('');
const [deleteTarget, setDeleteTarget] = useState<number | null>(null);
const [deleteTargets, setDeleteTargets] = useState<string>('');
const [deleteResult, setDeleteResult] = useState<string>('');
const [deleteResults, setDeleteResults] = useState<string>('');
const [chatLog, setChatLog] = useState<React.ReactNode>('');
const [used, setUsed] = useState<React.ReactNode>('');
const [isVoiceEnabled, setIsVoiceEnabled] = useState<boolean>(false);
const [audioSrc, setAudioSrc] = useState<string>('');
const [isSending, setIsSending] = useState<boolean>(false);
const [chatDataLoad, setChatDataLoad] = useState<boolean>(false);
const [apiKey, setApiKey] = useState<string>('');
const [akNo, setAkNo] = useState<number>(0);

return (
<>
<Header
title={title}
/>

<OpenaiKey
apiKey={apiKey}
setApiKey={setApiKey}
setAkNo={setAkNo}
/>

{apiKey && (
<>
<ChatDataLoader
roomType={roomType}
deleteResult={deleteResult}
setDeleteResult={setDeleteResult}
deleteResults={deleteResults}
chatDataLoad={chatDataLoad}
setTotalCount={setTotalCount}
currentPage={currentPage}
searchQuery={searchQuery}
setUserQuery={setUserQuery}
setChatData={setChatData}
akNo={akNo}
/>

<ChatDataDisplay
roomType={roomType}
totalCount={totalCount}
currentPage={currentPage}
setCurrentPage={setCurrentPage}
totalPage={totalPage}
setTotalPage={setTotalPage}
userQuery={userQuery}
setUserQuery={setUserQuery}
setSearchQuery={setSearchQuery}
chatData={chatData}
setDeleteTarget={setDeleteTarget}
isSending={isSending}
setChatDataLoad={setChatDataLoad}
messages={messages}
setMessages={setMessages}
/>

<ChatDataDeleter
roomType={roomType}
setCurrentPage={setCurrentPage}
deleteTarget={deleteTarget}
setDeleteTarget={setDeleteTarget}
setDeleteResult={setDeleteResult}
/>

<ChatDatasDeleteButton
setDeleteTargets={setDeleteTargets}
roomType={roomType}
chatData={chatData}
/>

<ChatDatasDeleter
setCurrentPage={setCurrentPage}
deleteTargets={deleteTargets}
setDeleteTargets={setDeleteTargets}
deleteResults={deleteResults}
setDeleteResults={setDeleteResults}
akNo={akNo}
/>

<ChatSendButton
title={title}
roomType={roomType}
model={model}
setModel={setModel}
userInput={userInput}
setUserInput={setUserInput}
messages={messages}
setMessages={setMessages}
isVoiceEnabled={isVoiceEnabled}
setIsVoiceEnabled={setIsVoiceEnabled}
isSending={isSending}
setIsSending={setIsSending}
setCurrentPage={setCurrentPage}
setSearchQuery={setSearchQuery}
setChatDataLoad={setChatDataLoad}
apiKey={apiKey}
/>

<ChatInitButton
setMessages={setMessages}
chatLog={chatLog}
setChatLog={setChatLog}
setUsed={setUsed}
setSaveResult={setSaveResult}
isVoiceEnabled={isVoiceEnabled}
setIsVoiceEnabled={setIsVoiceEnabled}
setAudioSrc={setAudioSrc}
setChatDataLoad={setChatDataLoad}
isSending={isSending}
/>

<ChatDisplay
roomType={roomType}
setChatData={setChatData}
chatLog={chatLog}
setChatLog={setChatLog}
used={used}
setUsed={setUsed}
model={model}
messages={messages}
setMessages={setMessages}
userInput={userInput}
setUserInput={setUserInput}
saveResult={saveResult}
setSaveResult={setSaveResult}
setDeleteResults={setDeleteResults}
isVoiceEnabled={isVoiceEnabled}
audioSrc={audioSrc}
setAudioSrc={setAudioSrc}
setIsSending={setIsSending}
chatDataLoad={chatDataLoad}
apiKey={apiKey}
akNo={akNo}
/>
</>
)}
</>
);
}

export default Gpt;
