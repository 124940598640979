import React, { useState } from 'react';

import Header from '../../hooks/common/header';
import { OpenaiKey } from '../../hooks/common/key';
import TransDataLoader from '../../hooks/whisper/transdataloader';
import TransDataDisplay from '../../hooks/whisper/transdatadisplay';
import TransDataDeleter from '../../hooks/whisper/transdatadeleter';
import TransDatasDeleteButton from '../../hooks/whisper/transdatasdeletebutton';
import TransDatasDeleter from '../../hooks/whisper/transdatasdeleter';
import FileSendButton from '../../hooks/whisper/filesendbutton';
import TransDisplay from '../../hooks/whisper/transdisplay';

import { TransData } from '../../@types/data';

interface TransProps {
title: string;
roomType: string;
}

const Whisper: React.FC<TransProps> = ({
title,
roomType
}) => {
const [totalCount, setTotalCount] = useState<number>(0);
const [currentPage, setCurrentPage] = useState<number>(1);
const [totalPage, setTotalPage] = useState<number>(1);
const [userQuery, setUserQuery] = useState<string>('');
const [searchQuery, setSearchQuery] = useState<string>('');
const [transData, setTransData] = useState<TransData[]>([]);
const [file, setFile] = useState<File | null>(null);
const [source, setSource] = useState<File | null>(null);
const [saveResult, setSaveResult] = useState<string>('');
const [deleteTarget, setDeleteTarget] = useState<number | null>(null);
const [deleteTargets, setDeleteTargets] = useState<string>('');
const [deleteResult, setDeleteResult] = useState<string>('');
const [deleteResults, setDeleteResults] = useState<string>('');
const [output, setOutput] = useState<React.ReactNode>('');
const [isSending, setIsSending] = useState<boolean>(false);
const [transDataLoad, setTransDataLoad] = useState<boolean>(false);
const [apiKey, setApiKey] = useState<string>('');
const [akNo, setAkNo] = useState<number>(0);

return (
<>
<Header
title={title}
/>

<OpenaiKey
apiKey={apiKey}
setApiKey={setApiKey}
setAkNo={setAkNo}
/>

{apiKey && (
<>
<TransDataLoader
roomType={roomType}
deleteResult={deleteResult}
setDeleteResult={setDeleteResult}
deleteResults={deleteResults}
transDataLoad={transDataLoad}
setTotalCount={setTotalCount}
currentPage={currentPage}
searchQuery={searchQuery}
setUserQuery={setUserQuery}
setTransData={setTransData}
akNo={akNo}
/>

<TransDataDisplay
roomType={roomType}
totalCount={totalCount}
currentPage={currentPage}
setCurrentPage={setCurrentPage}
totalPage={totalPage}
setTotalPage={setTotalPage}
userQuery={userQuery}
setUserQuery={setUserQuery}
setSearchQuery={setSearchQuery}
transData={transData}
setTransDataLoad={setTransDataLoad}
setDeleteTarget={setDeleteTarget}
/>

<TransDataDeleter
roomType={roomType}
setCurrentPage={setCurrentPage}
deleteTarget={deleteTarget}
setDeleteTarget={setDeleteTarget}
setDeleteResult={setDeleteResult}
/>

<TransDatasDeleteButton
setDeleteTargets={setDeleteTargets}
roomType={roomType}
transData={transData}
/>

<TransDatasDeleter
setCurrentPage={setCurrentPage}
deleteTargets={deleteTargets}
setDeleteTargets={setDeleteTargets}
deleteResults={deleteResults}
setDeleteResults={setDeleteResults}
akNo={akNo}
/>

<FileSendButton
title={title}
roomType={roomType}
file={file}
setFile={setFile}
setSource={setSource}
setOutput={setOutput}
isSending={isSending}
setIsSending={setIsSending}
setCurrentPage={setCurrentPage}
setSearchQuery={setSearchQuery}
setTransDataLoad={setTransDataLoad}
/>

<TransDisplay
roomType={roomType}
setTransData={setTransData}
setFile={setFile}
source={source}
setSource={setSource}
output={output}
setOutput={setOutput}
saveResult={saveResult}
setSaveResult={setSaveResult}
setDeleteResults={setDeleteResults}
setIsSending={setIsSending}
transDataLoad={transDataLoad}
apiKey={apiKey}
akNo={akNo}
/>
</>
)}
</>
);
}

export default Whisper;
