import React, { useEffect, useState, FC } from 'react';
import axios from 'axios';

import { axiosErrorHandler } from '../common/error';
import createChatLog from './createchatlog';
import createUsed from './createused';

import { ChatData } from '../../@types/data';
import { Messages } from '../../@types/messages';

interface ChatDisplayProps {
roomType: string;
setChatData: React.Dispatch<React.SetStateAction<ChatData[]>>;
chatLog: React.ReactNode;
setChatLog: React.Dispatch<React.SetStateAction<React.ReactNode>>;
used: React.ReactNode;
setUsed: React.Dispatch<React.SetStateAction<React.ReactNode>>;
model: string;
messages: Messages[];
setMessages: React.Dispatch<React.SetStateAction<Messages[]>>;
userInput: string;
setUserInput: React.Dispatch<React.SetStateAction<string>>;
saveResult: string;
setSaveResult: React.Dispatch<React.SetStateAction<string>>;
setDeleteResults: React.Dispatch<React.SetStateAction<string>>;
isVoiceEnabled: boolean;
audioSrc: string;
setAudioSrc: React.Dispatch<React.SetStateAction<string>>;
setIsSending: React.Dispatch<React.SetStateAction<boolean>>;
chatDataLoad: boolean;
apiKey: string;
akNo: number;
}

const ChatDisplay: FC<ChatDisplayProps> = ({
roomType,
setChatData,
chatLog,
setChatLog,
used,
setUsed,
model,
messages,
setMessages,
userInput,
setUserInput,
saveResult,
setSaveResult,
setDeleteResults,
isVoiceEnabled,
audioSrc,
setAudioSrc,
setIsSending,
chatDataLoad,
apiKey,
akNo
}) => {
const [error, setError] = useState<string>('');

useEffect(() => {
const SendRequest = async () => {
setDeleteResults('');
setUsed('');
setSaveResult('');
setChatLog('잠시만 기다려 주세요.');

let data = {
model: model,
messages: messages,
};

const config = {
headers: {
Authorization: `Bearer ${apiKey}`,
'Content-Type': 'application/json',
},
};
let botResponse: string;
let promptTokens: number;
let completionTokens: number;
let totalTokens: number;
let reasoningTokens: number;

try {
const response = await axios.post('https://api.openai.com/v1/chat/completions', data, config);
botResponse = response.data.choices[0].message.content;
promptTokens = response.data.usage.prompt_tokens;
completionTokens = response.data.usage.completion_tokens;
totalTokens = response.data.usage.total_tokens;
reasoningTokens = response.data.usage.completion_tokens_details.reasoning_tokens;
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setUserInput('');
setChatLog('');
setIsSending(false);
return;
}

try {
const saveResponse = await axios.post(`${process.env.REACT_APP_API_URL}/saveChat`, {
userInput: userInput,
botResponse: botResponse,
roomType: roomType,
akNo: akNo
}, {
headers: {
'Content-Type': 'application/json'
}
});
const result = saveResponse.data;
setSaveResult(result);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setUserInput('');
setChatLog('');
setIsSending(false);
return;
}

setChatLog(createChatLog(userInput, botResponse));
setUsed(createUsed(promptTokens, completionTokens, totalTokens, reasoningTokens));
setMessages([...messages, { role: 'assistant', content: botResponse }]);
setUserInput('');
setIsSending(false);

if (!isVoiceEnabled) {
return;
}

try {
const response = await axios.post(
'https://api.openai.com/v1/audio/speech',
{
model: "tts-1-hd",
voice: "nova",
input: botResponse
},
{
headers: {
'Authorization': `Bearer ${apiKey}`,
'Content-Type': 'application/json',
},
responseType: 'blob',
}
);

const audioUrl = URL.createObjectURL(new Blob([response.data], { type: 'audio/mpeg' }));
setAudioSrc(audioUrl);
setIsSending(false);
setError('');
} catch (err) {
axiosErrorHandler(err, setError);
setIsSending(false);
}
};

if (messages[messages.length - 1]?.role === 'user' && !chatDataLoad && !error) {
SendRequest();
}
}, [roomType, setChatData, setChatLog, setUsed, model, messages, setMessages, userInput, setUserInput, setSaveResult, setDeleteResults, isVoiceEnabled, setAudioSrc, setIsSending, chatDataLoad, apiKey, akNo, error, setError]);

return (
<>
<div aria-live="polite">
{chatLog}
</div>
{used}
{error && (
<div className="error" aria-live="polite">
{error}
<br /><br />
</div>
)}
{saveResult}
{isVoiceEnabled && audioSrc && <audio controls autoPlay src={audioSrc}></audio>}
</>
);
};

export default ChatDisplay;
