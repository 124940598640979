import React, { useState } from 'react';

import Header from '../../hooks/common/header';
import { OpenaiKey } from '../../hooks/common/key';
import ChatSendButton from '../../hooks/audio/chatsendbutton';
import ChatInitButton from '../../hooks/audio/chatinitbutton';
import ChatDisplay from '../../hooks/audio/chatdisplay';

import { AudioMessages } from '../../@types/messages';

interface AudioProps {
title: string;
roomType: string;
}

const Audio: React.FC<AudioProps> = ({
title,
roomType
}) => {
const [model, setModel] = useState<string>('gpt-4o-audio-preview');
const [userInput, setUserInput] = useState<string>('');
const [file, setFile] = useState<File | null>(null);
const [messages, setMessages] = useState<AudioMessages[]>([]);
const [chatLog, setChatLog] = useState<React.ReactNode>('');
const [voice, setVoice] = useState<string>('nova');
const [audioSrc, setAudioSrc] = useState<string>('');
const [isVoiceSelected, setIsVoiceSelected] = useState<boolean>(false);
const [isSending, setIsSending] = useState<boolean>(false);
const [apiKey, setApiKey] = useState<string>('');

return (
<>
<Header
title={title}
/>

<OpenaiKey
apiKey={apiKey}
setApiKey={setApiKey}
/>

{apiKey && (
<>
<ChatSendButton
title={title}
roomType={roomType}
model={model}
setModel={setModel}
userInput={userInput}
setUserInput={setUserInput}
file={file}
setFile={setFile}
messages={messages}
setMessages={setMessages}
voice={voice}
setVoice={setVoice}
isVoiceSelected={isVoiceSelected}
setIsVoiceSelected={setIsVoiceSelected}
isSending={isSending}
setIsSending={setIsSending}
apiKey={apiKey}
/>

<ChatInitButton
setMessages={setMessages}
chatLog={chatLog}
setChatLog={setChatLog}
setVoice={setVoice}
setAudioSrc={setAudioSrc}
setIsVoiceSelected={setIsVoiceSelected}
isSending={isSending}
/>

<ChatDisplay
roomType={roomType}
chatLog={chatLog}
setChatLog={setChatLog}
model={model}
messages={messages}
setMessages={setMessages}
userInput={userInput}
setUserInput={setUserInput}
setFile={setFile}
voice={voice}
audioSrc={audioSrc}
setAudioSrc={setAudioSrc}
setIsSending={setIsSending}
apiKey={apiKey}
/>
</>
)}
</>
);
}

export default Audio;
